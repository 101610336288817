<template>
    <b-container fluid>
        <div v-if="store_workers">
            <div class="text-right">
                <button class="btn btn-danger btn-sm mr-2" @click="saveWeek({active: 1})" :disabled="week.active == 1">
                    Woche freigeben
                </button>
                <button class="btn btn-primary btn-sm" @click="saveWeek()" :disabled="type != ''">
                    <i class="fas fa-save"></i> Speichern
                </button>
            </div>
            <br>

            <div class="overflow-auto">
                <table class="table table-hover text-center">
                    <thead>
                        <tr>
                            <th>
                                
                            </th>
                            <th v-for="date in range" :key="date.format('YYYY-MM-DD')">
                                {{date.format('dddd')}} ({{date | formatDate}})
                            </th>
                            <th>
                                Stunden gesamt (Filiale)
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(store, storeIndex) in $parent.stores" :key="store.id">
                            <td>

                            </td>
                            <td v-for="(date, dateIndex) in range" :key="date.format('DD-MM-YYYY')">
                                <div class="card card-success" style="min-width: 15vw">
                                    <div class="card-header">
                                        <div class="card-title text-left">
                                            {{store.name}} <br>
                                            <small v-if="store.id != 0">
                                                <template v-if="date.format('dddd') != 'Sonntag'">
                                                    <template>
                                                        <template v-if="hasOpenSunday(store, date)">
                                                                <span class="text-danger">{{hasOpenSunday(store, date).start_time}} - {{hasOpenSunday(store, date).stop_time}}</span>
                                                        </template>
                                                        <template v-else>
                                                            <template v-if="findBusinessHours(store, date) != null">
                                                                    {{findBusinessHours(store, date).open_time | toTime}} - {{findBusinessHours(store, date).close_time | toTime}}
                                                            </template>
                                                            <template v-else>
                                                                <span class="text-danger">Geschlossen</span>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="hasOpenSunday(store, date)">
                                                        <span class="text-danger">{{hasOpenSunday(store, date).start_time}} - {{hasOpenSunday(store, date).stop_time}}</span>
                                                    </template>
                                                    <template v-else>
                                                        <span class="text-danger">Geschlossen</span>
                                                    </template>
                                                </template>
                                            </small>
                                        </div>
                                        <div class="card-tools">
                                            <button class="btn btn-xs btn-light" @click="createWorker(store, date, storeIndex, dateIndex)" :disabled="type != ''">
                                                <i class="fas fa-plus"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="card-body p-2">
                                        <div class="row" v-for="(worker, workerIndex) in getWorkersOnDay(date.format('YYYY-MM-DD'), store.id)" :key="worker.id" :class="workerExistsInOtherStore(date, store.id, worker.employee_id) == true ? 'callout callout-danger': ''">
                                            <draggable v-model="store_workers">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-10 text-left">
                                                            <div v-if="store_selected == storeIndex && date_selected == dateIndex && worker.store_workers_index == store_workers_index && type == 'name'">
                                                                <div class="row">
                                                                    <div class="col-sm-12">
                                                                        <multiselect :group-select="false" :maxHeight="200" class="text-sm" :options="options" :multiple="false" group-values="employees" group-label="group" placeholder="Suche..." track-by="name" label="name" v-model="worker.employee_id" language="de-DE" :showLabels="false" :closeOnSelect="true">
                                                                            <span slot="noResult">Oops...</span>
                                                                        </multiselect>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-sm-5">
                                                                        <input type="time" class="form-control form-control-xs text-xs p-1 m-1" v-model="worker.start_time">
                                                                    </div>
                                                                    <div class="col-xs-1">
            
                                                                    </div>
                                                                    <div class="col-sm-5">
                                                                        <input type="time" class="form-control form-control-xs text-xs p-1 m-1" v-model="worker.stop_time">
                                                                    </div>
                                                                    <div class="col-sm-1 d-flex align-items-center">
                                                                        <button class="btn btn-xs btn-secondary" @click="saveEmployee(worker)">
                                                                            <i class="fas fa-save"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                {{worker.employee.first_name}} {{worker.employee.last_name}} <br>
                                                            </div>
            
            
                                                            <small>
                                                                <!-- {{worker.start_time | toTime}} - {{worker.stop_time  | toTime}}  -->
                                                                <div class="row" v-if="(store_selected == storeIndex && date_selected == dateIndex && worker_selected == workerIndex && type == 'time')">
                                                                    <div class="col-sm-5">
                                                                        <input type="time" class="form-control form-control-xs text-xs p-1 m-1" v-model="worker.start_time">
                                                                    </div>
                                                                    <div class="col-xs-1">
            
                                                                    </div>
                                                                    <div class="col-sm-5">
                                                                        <input type="time" class="form-control form-control-xs text-xs p-1 m-1" v-model="worker.stop_time">
                                                                    </div>
                                                                    <div class="col-sm-1 d-flex align-items-center">
                                                                        <button class="btn btn-sm btn-secondary btn-outline" @click="saveWorkTime()">
                                                                            <!-- <i class="fas fa-pencil-alt"></i> -->
                                                                            <i class="fas fa-save"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <p class="text-xs" v-else-if="(store_selected == storeIndex && date_selected == dateIndex && worker.store_workers_index == store_workers_index && type == 'name')">
            
                                                                </p>
                                                                <p class="text-xs" v-else>
                                                                    {{worker.start_time | toTime}} bis {{worker.stop_time | toTime}}
                                                                    <button class="btn btn-sm" @click="editWorkTime(storeIndex, dateIndex, workerIndex)" :disabled="type != ''">
                                                                        <i class="fas fa-pencil-alt"></i>
                                                                    </button>
                                                                </p>
                                                            </small>
                                                        </div>
                                                        <div class="col-2">
                                                            <button class="btn btn-xs" @click="removeEmployee(worker)">
                                                                <i class="fas fa-times"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </draggable>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text-lg" style="vertical-align: middle;">
                                {{calculateStoreTimes(store.id)}} Std.
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Stunden gesamt (Tag)
                            </th>
                            <td v-for="date in range" :key="date.format('YYYY-DD-MM')" class="text-lg" style="vertical-align: middle;">
                                {{calculateDayTime(date)}} Std.
                            </td>
                            <td class="text-center" style="vertical-align: middle">
                                
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-right">
                <button class="btn btn-primary btn-sm" @click="saveWeek()" :disabled="type != ''">
                    <i class="fas fa-save"></i> Speichern
                </button>
            </div>
        </div>
        <aside class="control-sidebar control-sidebar-light ">
            <div class="p-0 overflow-auto" style="height: 100%;">
                <center>
                    <h2>Mitarbeiter</h2>
                </center>
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>
                                B. Std.
                            </th>
                            <th>
                                W. Std
                            </th>
                            <th>
                                M. Std
                            </th>
                        </tr>
                    </thead>
                    <tbody v-for="group in groups" :key="group.id">
                        <tr v-for="employee in group.employees" :key="employee.id">
                            <td>{{employee.first_name}} {{employee.last_name}}</td>
                            <td>
                                {{Math.round((parseFloat(employee.base_time) / 4.33))}}
                            </td>
                            <td>
                                {{calculateEmployeeTime(employee.id)}}
                            </td>
                            <td>
                                {{ calculateHours(employee).toFixed(2) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table v-for="group in groups" :key="group.id" class="table">
                    <thead>
                        <tr>
                            <td>
                                <b>{{ group.name }}</b>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <draggable tag="tbody" :list="group.employees" item-key="id" :group="{ name: group.name, pull: 'clone', put: false }">
                        <tr v-for="employee in group.employees" :key="employee.id">
                            <td>{{employee.first_name}} {{employee.last_name}}</td>
                            <td>
                                {{Math.round((parseFloat(employee.base_time) / 4.33))}}
                            </td>
                            <td>
                                {{calculateEmployeeTime(employee.id)}}
                            </td>
                            <td>
                                {{ calculateHours(employee).toFixed(2) }}
                            </td>
                        </tr>
                    </draggable>
                </table>
                <!-- <div v-for="group in groups" :key="group.id">
                    <template>
                        <div class="card-header">
                            <div class="card-title">
                                <b>{{group.name}}</b>
                            </div>
                        </div>
                        <div class="">
                            <table class="table table-hover">
                                <tbody>
                                    <tr v-for="employee in group.employees" :key="employee.id">
                                        <td>{{employee.first_name}} {{employee.last_name}}</td>
                                        <td>
                                            {{Math.round((parseFloat(employee.base_time) / 4.33))}}
                                        </td>
                                        <td>
                                            {{calculateEmployeeTime(employee.id)}}
                                        </td>
                                        <td>
                                            {{ calculateHours(employee).toFixed(2) }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </div> -->
            </div>
        </aside>
    </b-container>
</template>

<script>
// import { mapPreferences } from 'vue-preferences'
import draggable from 'vuedraggable'
import moment from 'moment'
export default {
    name: 'EmployeeWorkplanWeeklyView',
    props: ['selectedWeek', 'groups'],
    components: {
        draggable
    },
    watch: {
        selectedWeek: {
            handler() {
                this.generateWeekRange();
            },
            deep: true
        },
        groups: {
            handler()
            {

            },
            deep: true,
        }
    },
    data() {
        return {
            range: [],
            store_workers: [],

            store_selected: null,
            date_selected: null,
            worker_selected: null,
            store_workers_index: null,
            type: '',


            workplan_weeks_id: null,
            week: [],
            test: [],
            options: [],
        }
    },
    methods: {
        hasOpenSunday(store, date)
        {
            let open_sunday = store.open_sundays.find(element => element.date == date.format('YYYY-MM-DD'));
            if(open_sunday != undefined)
            {
                if(open_sunday.start_time != null && open_sunday.stop_time != null)
                {
                    return open_sunday;
                } else
                {
                    return null;
                }
            }
            return null;
        },
        findBusinessHours(store, date)
        {
            // console.log(store);
            return store.business_hours.find(element => element.day_of_week == moment(date).isoWeekday()-1);
        },
        isInHolidays(date, employee) 
        {
            let absend = false;
            employee.holidays.forEach(element => {
                if(element.state.name == 'confirmed' || element.state.name == 'confirmed_unpaid' || element.state.name == 'ill' || element.state.name == 'ill_unpaid' || element.state.name == 'absend')
                {
                    let date1 = moment(this.$parent.parseDate(element.start_date));
                    let date2 = moment(this.$parent.parseDate(element.end_date));
                    if(date.isBetween(date1, date2, 'days', []))
                    {
                        absend = true;
                    }
                }
            });
            return absend;
        },

        isAbsend(date, employee)
        {
            let absend = false;
            employee.holidays.forEach(element => {
                if(element.state.name != 'absend' && element.state.name != 'absend_morning' && element.state.name != 'absend_afternoon')
                {
                    let date1 = moment(this.$parent.parseDate(element.start_date));
                    let date2 = moment(this.$parent.parseDate(element.end_date));
                    if(date.isBetween(date1, date2, 'days', []))
                    {
                        absend = element;
                    }
                }
            });
            return absend;
        },
        getAbsend(date, employee)
        {
            let absend = '';
            employee.holidays.forEach(element => {
                let date1 = moment(this.$parent.parseDate(element.start_date));
                let date2 = moment(this.$parent.parseDate(element.end_date));
                if(date.isBetween(date1, date2, 'days', []))
                {
                    if(element.state.name == 'absend')
                    {
                        absend = 'A'
                    } else if(element.state.name == 'absend_morning')
                    {
                        absend = 'AV'
                    } else if(element.state.name == 'absend_afternoon')
                    {
                        absend = 'AN'
                    } else if(element.state.name == 'confirmed' || element.state.name == 'confirmed_unpaid')
                    {
                        absend = 'U'
                    } else if(element.state.name == 'ill' || element.state.name == 'ill_unpaid')
                    {
                        absend = 'K'
                    }
                }
            });
            if(absend != '')
            {
                absend += " - ";
            }
            return absend;
        },
        calculateStoreTimes(store_id)
        {
            let time = 0;
            this.store_workers.forEach(worker => {
                if(worker.store_id == store_id)
                {
                    let time_temp = this.timeToDecimal(worker.stop_time) - this.timeToDecimal(worker.start_time);
                    if(time_temp > 6.0)
                    {
                        time_temp -= 0.5;
                    }
                    time += time_temp;
                }
            })
            return time;
        },
        calculateAllStoreTimes()
        {

            let time = 0;
            this.store_workers.forEach(worker => {
                let time_temp = this.timeToDecimal(worker.stop_time) - this.timeToDecimal(worker.start_time);
                if(time_temp > 6.0)
                {
                    time_temp -= 0.5;
                }
                time += time_temp;
            })
            return time;
        },
        calculateDayTime(date)
        {
            let time = 0;
            this.store_workers.forEach(worker => {
                if(worker.date == date.format('YYYY-MM-DD'))
                {
                    let time_temp = this.timeToDecimal(worker.stop_time) - this.timeToDecimal(worker.start_time);
                    if(time_temp > 6.0)
                    {
                        time_temp -= 0.5;
                    }
                    time += time_temp;
                }
            })
            return Math.round(time * 10) / 10;
        },
        calculateEmployeeTime(employee_id)
        {
            let time = 0;
            this.store_workers.forEach(worker => {
                if(worker.employee_id == employee_id)
                {
                    let time_temp = this.timeToDecimal(worker.stop_time) - this.timeToDecimal(worker.start_time);
                    if(time_temp > 6.0)
                    {
                        time_temp -= 0.5;
                    }
                    time += time_temp;
                }
            })
            return Math.round(time * 10) / 10;
        },
        timeToDecimal(t) {
            var arr = t.split(':');
            var dec = parseInt((arr[1]/6)*10, 10);

            return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
        },
        getStoreWorkers() {
            this.axios
                .get("workplan-weeks/show", {
                    params: {
                        year: this.$parent.year,
                        week: this.$parent.selectedWeek,
                    }
                })
                .then((response) => {
                    this.store_workers = response.data.data.workers;
                    this.workplan_weeks_id = response.data.data.id;
                    this.week = response.data.data;
                    this.reset();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden des Arbeitsplan ist etwas schief gelaufen'
                    })
                })
        },
        reset() {
            this.store_selected = null;
            this.date_selected = null;
            this.worker_selected = null;
            this.type = '';
        },

        /*

            Generators

        */
        generateWeekRange()
        {
            let index = this.$parent.weeks.findIndex(element => element.weeknumber == this.selectedWeek);
            if(index != -1)
            {
                let week = this.$parent.weeks[index];
                this.range = this.$parent.createRange(week.start_date, week.end_date);
                this.getStoreWorkers();
            }
        },

        /*

            Edit Worktime

        */
        editWorkTime(store, date, worker)
        {
            this.store_selected = store;
            this.date_selected = date;
            this.worker_selected = worker;
            this.type = 'time';
        },
        saveWorkTime()
        {
            this.store_selected = null;
            this.date_selected = null;
            this.worker_selected = null;
            this.type = '';
            this.$parent.hasChanges = true;
        },

        /*

            Create new worker

        */
        createWorker(store, date, storeIndex, dateIndex)
        {
            let index = this.store_workers.push({
                id: this.store_workers[this.store_workers.length-1] == undefined ? 0 : this.store_workers[this.store_workers.length-1].id + 1,
                store_id: store.id,
                date: date.format('YYYY-MM-DD'),
                employee_id: 0,
                start_time: '00:00:00',
                stop_time: '00:00:00'
            }) - 1;
            this.store_workers[index].store_workers_index = index;

            this.store_selected = storeIndex,
            this.date_selected = dateIndex;
            this.store_workers_index = index;
            this.type = 'name';

                this.options = [];
                this.groups.forEach(element => {
                    let employees = [];
                    element.employees.forEach(emp => {
                        employees.push({
                            name: this.getAbsend(date, emp) + ' ' + emp.first_name + ' ' + emp.last_name,
                            value: emp.id,
                            $isDisabled: this.isInHolidays(date, emp),
                        })
                    });
                    this.options.push({
                        group: element.name,
                        employees,
                    });
                });
        },
        saveEmployee(worker)
        {
            let find = this.$parent.employees.findIndex(element => element.id == worker.employee_id.value);
            if(find != -1)
            {
                this.$parent.employees[find].employee_id = worker.employee_id.value
                worker.employee_id = worker.employee_id.value;
                worker.employee = {...this.$parent.employees[find]};
                this.store_selected = null,
                this.date_selected = null;
                this.type = '';
                this.$parent.hasChanges = true;
            }
        },
        removeEmployee(worker)
        {
            this.store_workers.splice(worker.store_workers_index, 1);
            this.type = '';
            this.$parent.hasChanges = true;
        },

        /*

            Check somthing

        */

        workerExistsInOtherStore(date, store_id, worker_id)
        {
            let count = 0;
            this.store_workers.forEach(element => {
                if(element.date == date.format('YYYY-MM-DD') && element.employee_id == worker_id)
                {
                    count++;
                }
            })
            if(count > 1)
            {
                return true;
            } else
            {
                return false;
            }
        },

        /*

            Helpers

        */

        getWorkersOnDay(date, store_id)
        {
            let workers = [];

            this.store_workers.forEach((element, index) => {
                if(element.date == date && element.store_id == store_id)
                {
                    element.store_workers_index = index;
                    workers.push(element);
                }
            })
            return workers;
        },
        
        saveWeek(active = null)
        {
            this.axios
                .put("/workplan-weeks/update/" + this.workplan_weeks_id, {
                    ...active,
                    workplan: this.store_workers
                })
                .then((response) => {
                    this.store_workers = response.data.data.workers;
                    this.store_workers_index = response.data.data.id;
                    this.week = response.data.data;
                    this.$parent.hasChanges = false;
                    if(active != null)
                    {
                        this.$parent.getWeeks();
                    }
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim Speichern ist etwas schief gelaufen'
                    })
                })
        },
        calculateHours(employee) {
            var date = this.range[0];
            if(employee.salaries != undefined)
            {
                var salarie = employee.salaries.find(element => (element.year == date.format('Y') && element.month == date.format('M')));
                var hours = 0;
                if(salarie != undefined)
                {
                    salarie.times.forEach(element => {
                        // hours += moment(element.start_date).diff(moment(element.end_date), 'hours');
                        if(element.end_time != null && element.start_time != null)
                            hours += this.timeToDecimal(element.end_time) - this.timeToDecimal(element.start_time);

                        if(element.second_end_time != null && element.second_start_time != null)
                            hours += this.timeToDecimal(element.second_end_time) - this.timeToDecimal(element.second_start_time);
                    });
                    return hours;
                }
                return 0;
            }
            return 0;
        },
    },
    mounted() {
        this.generateWeekRange();
    },

    computed: {
    },
}
</script>

<style scope>

</style>